.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  padding: 4rem 1rem;
}

.container {
  max-width: 75rem;
  margin: 0 auto;
}

.card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  margin-bottom: 1.5rem;
}

.title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
  margin-top: 0;
}

.description {
  color: #4b5563;
  margin-bottom: 1rem;
  margin-top: 0;
}

.highlight {
  display: flex;
  align-items: center;
  color: #185adb;
  font-weight: 600;
}

.icon {
  width: 2.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.formContainer {
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  position: relative;
  margin-bottom: 1rem;
}

.input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 2px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #185adb;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
}

.label {
  position: absolute;
  left: 0.75rem;
  top: 0.5rem;
  color: #6b7280;
  transition: all 0.3s ease;
  pointer-events: none;
}

.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label {
  top: -1.5rem;
  left: 0;
  font-size: 0.875rem;
  color: #185adb;
}

.button {
  background-color: #185adb;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.5);
}

.successMessage {
  background-color: #d1fae5;
  border: 1px solid #34d399;
  color: #047857;
  padding: 1rem;
  border-radius: 0.375rem;
  animation: fadeInDown 0.5s ease-out;
}

.successTitle {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.successDescription {
  font-size: 0.875rem;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .card {
    flex-direction: row;
    align-items: center;
  }

  .content {
    width: 50%;
    padding-right: 2.5rem;
    margin-bottom: 0;
  }

  .formContainer {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .title {
    margin-bottom: 0rem;
  }
}
